import React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import { SEO, H1, H2, H3, Paragraph } from '@renderbus/common/components'
import Layout from '../molecules/layout'
import partnerDetails from '../molecules/partner/partnerDetails'
import {
  PartnerBanner,
  TextContent,
  Section,
  PartnerLogoList,
  PartnerDetails,
} from './partner.atom'

const Partner = props => {
  const {
    location,
    data,
    pageContext: { topThreeShare },
  } = props

  const { bannerImage, bannerImageMb } = data

  return (
    <Layout location={location} topThreeShare={topThreeShare}>
      <SEO
        title='Renderbus瑞云渲染合作伙伴简介-深圳市瑞云科技股份有限公司'
        keywords='Renderbus瑞云渲染,云渲染农场'
        description='Renderbus瑞云渲染为CG行业知名云渲染农场,合作伙伴有Chaos Group、Autodesk、IBM、阿里云、Nvidia等等知名企业,强强联合,旨在提供优质云渲染服务。'
      />
      <PartnerBanner>
        <GatsbyImage
          imgStyle={{ filter: 'brightness(.8)' }}
          style={{ height: '100%' }}
          fluid={[
            bannerImage.childImageSharp.fluid,
            { media: '(max-width: 780px)', ...bannerImageMb.childImageSharp.fluid },
          ]}
        />
        <TextContent>
          <H1>携手瑞云｜推动全球影视发展</H1>
          <Paragraph>
            瑞云科技专注于研究集群渲染、并行计算技术，为全球电脑动画、电影特效、电视动画片、建筑设计表现、游戏片头动画和商业广告行业提供优质云端渲染计算服务。
          </Paragraph>
        </TextContent>
      </PartnerBanner>
      <Section>
        <div className='section-content'>
          <H2>我们的合作伙伴</H2>
          <PartnerLogoList>
            {partnerDetails.map(({ src, title }) => (
              <div className='partner-item' key={title}>
                <img src={src} alt={title} />
              </div>
            ))}
          </PartnerLogoList>
          <H3 className='partner-detail-title'>合作伙伴简介</H3>
          <PartnerDetails>
            {partnerDetails.map(({ content, title }) => (
              <div className='partner-detail-item' key={title}>
                <p className='title'>{title}</p>
                <p className='content'>{content}</p>
              </div>
            ))}
          </PartnerDetails>
        </div>
      </Section>
    </Layout>
  )
}

export default Partner

export const queryImage = graphql`
  query {
    bannerImageMb: file(relativePath: { eq: "partner/partner-banner-mb.webp" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bannerImage: file(relativePath: { eq: "partner/partner-banner.webp" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
